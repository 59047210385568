import Barba from '@barba/core'

export const selector = 'ul.slideshow'

export function hydrate (element) {
  let slides, nextTick, index
  const duration = +(element.dataset.duration || 1000)

  bind()
  Barba.hooks.enter(bind)

  function bind () {
    window.clearTimeout(nextTick)

    index = -1
    slides = element.querySelectorAll('li')
    tick()

    for (const slide of slides) {
      slide.addEventListener('click', tick)
    }
  }

  function tick () {
    window.clearTimeout(nextTick)

    element.classList.add('has-started')
    index = (index + 1) % slides.length

    const current = element.querySelector('li.is-visible')
    if (current) {
      current.animate([{ opacity: 1 }, { opacity: 0 }], { duration: 300 })
    }

    for (let i = 0; i < slides.length; i++) {
      slides[i].classList.toggle('is-visible', index === i)
    }

    nextTick = window.setTimeout(tick, duration)
  }

  return {
    destroy: () => window.clearTimeout(nextTick)
  }
}
