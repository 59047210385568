import Barba from '@barba/core'
import Photoswipe from 'photoswipe'
import PhotoswipeUIDefault from 'photoswipe/dist/photoswipe-ui-default'
import isMobile from 'utils/is-mobile'

export const selector = '.pswp'
export function hydrate (element) {
  let photoswipe
  let slides = []

  bind()
  Barba.hooks.enter(bind)

  function bind ({ next } = {}) {
    const container = (next || {}).container || document.body
    slides = Array.from(container.querySelectorAll('[data-photoswipe]')).map((figure, index) => {
      const img = figure.querySelector('img')
      if (!img) return

      figure.addEventListener('click', e => {
        e.preventDefault()
        open(index)
      })

      return {
        index,
        element: img,
        src: img.getAttribute('data-zoom-src'),
        w: +img.getAttribute('width'),
        h: +img.getAttribute('height')
      }
    })
  }

  function open (index = 0) {
    photoswipe = new Photoswipe(
      element,
      PhotoswipeUIDefault,
      slides,
      Object.assign({}, {
        bgOpacity: 1,
        history: false,
        allowPanToNext: false,
        barsSize: { top: 0, bottom: 0 },
        captionEl: false,
        fullscreenEl: false,
        closeEl: true,
        zoomEl: false,
        shareEl: true,
        counterEl: true,
        preloaderEl: false,
        showHideOpacity: true,
        timeToIdle: null,
        timeToIdleOutside: null,
        tapToToggleControls: false,
        maxSpreadZoom: isMobile ? 2 : 1,
        pinchToClose: isMobile,
        getDoubleTapZoom: (isMouseClick, item) => isMobile
          ? (item.initialZoomLevel < 0.7 ? 1 : 1.5)
          : item.initialZoomLevel
      }, { index })
    )

    photoswipe.init()
  }
}
