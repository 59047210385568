import Barba from '@barba/core'
import lozad from 'lozad'
import smoothscroll from 'smoothscroll-polyfill'

smoothscroll.polyfill()

Barba.init({
  debug: !window.ENV.production,
  prevent: ({ el }) => el.classList && el.classList.contains('no-barba'),
  views: [
    require('views/biography'),
    require('views/home'),
    require('views/works')
  ].map(v => v.default),
  transitions: [{
    sync: false,

    enter,
    once: (data) => {
      // Find and hydrate all global components
      for (const { selector, hydrate } of [
        require('components/Menu'),
        require('components/Photoswipe')
      ]) Array.from(document.querySelectorAll(selector)).map(hydrate)

      enter()
    },

    beforeLeave: () => {
      document.body.classList.add('is-loading')
    },

    afterEnter: () => {
      window.requestAnimationFrame(() => {
        document.body.classList.remove('is-loading')
      })
    }
  }]
})

function enter () {
  window.scrollTo(0, 0)
  smoothAnchors()
  lazy()
}

function lazy () {
  const lazys = document.body.querySelectorAll('[data-lazyload=true]')
  lozad(lazys).observe()

  // Lozad set a [data-loaded] attribute when loading image, but does not detect
  // when image is fully loaded, which can cause incoherent animations
  for (const image of lazys) {
    image.onload = () => image.setAttribute('data-decoded', true)
  }
}

function smoothAnchors () {
  for (const a of document.querySelectorAll('a[href^="#"]')) {
    a.addEventListener('click', e => {
      const id = a.getAttribute('href').substr(1)

      if (id === 'top') {
        e.preventDefault()
        return window.scroll({ top: 0, left: 0, behavior: 'smooth' })
      }

      const target = document.getElementById(id)
      if (!target) return
      e.preventDefault()
      target.scrollIntoView({ behavior: 'smooth' })
    })
  }
}
