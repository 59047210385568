import BarbaView from 'abstractions/BarbaView'
import * as Filter from 'components/Filter.jsx'

export default new BarbaView('works', {
  afterEnter: function ({ next }) {
    const works = next.container.querySelectorAll('li.work')

    const FILTERS = {
      year: Filter.hydrate(next.container.querySelector('#filterByYear'), {
        match: (value, data) => value === data,
        onChange: () => {
          let hasChanged = false
          const matches = update()

          // Disable zero-match technics
          for (const option of FILTERS.technic.element.querySelectorAll('option')) {
            if (option.innerText === '' || option.value === '0') continue
            const match = matches.find(m => FILTERS.technic.apply(m.dataset.technic, option.value))
            option.toggleAttribute('disabled', !match)
            if (option.value === option.parentNode.value && !match) {
              option.parentNode.value = '0'
              hasChanged = true
            }
          }

          if (hasChanged) update()
        }
      }),

      technic: Filter.hydrate(next.container.querySelector('#filterByTechnic'), {
        match: (value, data) => data.split(',').includes(value),
        autoSize: true,
        onChange: update
      })
    }

    update()

    function update () {
      const matches = []
      for (const work of works) {
        let match = true

        for (const [field, filter] of Object.entries(FILTERS)) {
          if (!filter.apply(work.dataset[field])) {
            match = false
            break
          }
        }

        if (match) matches.push(work)
        work.style.display = match ? '' : 'none'
      }

      return matches
    }
  }
})
