import { render } from 'utils/jsx'
import noop from 'utils/noop'

export const selector = '.filter'

export function hydrate (element, {
  autoSize = false,
  match = noop,
  onChange = noop
} = {}) {
  const helper = render(<div style='position: absolute; top: 0; left: -9999px' />, element.parentNode).nodes[0]

  const resize = () => {
    helper.innerHTML = element.querySelector('option:checked').innerText
    element.style.setProperty('--auto-width', helper.offsetWidth + 'px')
  }

  element.addEventListener('change', e => {
    if (autoSize) resize()
    onChange()
  })

  window.addEventListener('DOMContentLoaded', resize)
  resize()

  return {
    element,
    apply: function (data, value = element.value) {
      return value === '0' || match(value, data)
    }
  }
}
