import BarbaView from 'abstractions/BarbaView'
import { clamp } from 'missing-math'
import lastOf from 'utils/array-last'

export default new BarbaView('biography', {
  afterEnter: function ({ next }) {
    const nav = document.querySelector('main nav .js-scroll-wrapper')

    for (const child of nav.children) {
      child._target = document.getElementById(child.href && (child.href.match(/#(.*)/) || [])[1])
      if (!child._target) child.style.display = 'none'
      child.style.position = 'absolute'
    }

    this.update = () => window.requestAnimationFrame(() => {
      let lastY = 0
      const stickies = []

      for (let index = 0; index < nav.children.length; index++) {
        const child = nav.children[index]
        const targetY = child._target.getBoundingClientRect().y - nav.parentNode.offsetTop
        const y = clamp(
          targetY,
          lastY,
          nav.offsetHeight - (child.offsetHeight * (nav.children.length - index))
        )

        child.classList.remove('is-active')
        if (targetY < y) stickies.push(child)

        child.style.top = y + 'px'
        lastY = y + child.offsetHeight
      }

      ;(lastOf(stickies) || nav.children[0]).classList.add('is-active')
    })

    this.update()
    window.addEventListener('resize', this.update)
    window.addEventListener('scroll', this.update)
  },

  beforeLeave () {
    window.removeEventListener('resize', this.update)
    window.removeEventListener('scroll', this.update)
  }
})
